import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

const LANGUAGE_OPTIONS = ['English', 'Spanish'];

/**
 * Display to show / select the Language of the user
 * Uses Google Maps API for the maps component
 * @param {*} props 
 * @author BrianCastor
 */
export default function LanguageSelector(props) {
  const field = 'Language_PL__c';
  const [language, setLanguage] = React.useState(props.userData[field] || 'English');

  function handleLanguageSelected(event) {
    let selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    let newData = {};
    newData[field] = selectedLanguage;
    props.updateUserData(newData);
  };

  return (
    <div>
      <h3>Show services in</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{paddingLeft:'20px'}}>
      <RadioGroup aria-label="Languages" name="languages" value={language} onChange={(event) => handleLanguageSelected(event)}>
        {LANGUAGE_OPTIONS.map((lang, index) => {
          return (
            <FormControlLabel key={index} value={lang} control={<Radio color="primary" />} label={lang} />
          )
        })}
      </RadioGroup>
      </Grid>
      </Grid>
    </div>
  );
}