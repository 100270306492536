import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Profile from "../components/profile"
import Container from '@material-ui/core/Container'

const ProfilePage = () => (
  <Layout>
    <Seo title="Profile" />
    <Container maxWidth="sm" style={{ paddingTop: '10px' }}>
      <Profile />
    </Container>
  </Layout>
)

export default ProfilePage
