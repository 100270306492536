import React from 'react';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBd8LGEpU6x1Ev4bqetj28O0tuqtkn_ZHU';

/**
 * Component to display an embedded Google Map based off of a given address or geolocation (lat/long)
 * @param {*} props Requires either the 'address' prop (object containing address fields) or 'coordinates' prop (lat/long)
 * @author BrianCastor
 */
export const MapView = props => {
    const address = props.address;
    const coordinates = props.coordinates;
    let googleMapsQueryString = '';

    //Build the querystring for Google maps based off of address or coordinates
    if (address) {
        const fields = ['street', 'city', 'state', 'country', 'postalCode'];
        fields.forEach(field => {
            if (address[field]) {
                googleMapsQueryString += address[field] + ' ';
            }
        })
    }
    else if (coordinates) {
        googleMapsQueryString = `${coordinates.latitude},${coordinates.longitude}`;
    }
    else {
        return (<span>No location given</span>);
    }

    return (
        <div style={{ height: '375px', width: '100%' }}>
            <iframe
                width='100%'
                height="100%"
                title="Google Maps"
                style={{ border: '0'}}
                src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&zoom=14&q=${googleMapsQueryString}`}>
            </iframe>
        </div>
    );
}