import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';

import LanguageSelector from './userPreferenceComponents/languageSelector';
import ServicesSelector from './userPreferenceComponents/servicesSelector';
import DemographicsSelector from './userPreferenceComponents/demographicsSelector';
import SearchRadiusSelector from './userPreferenceComponents/searchRadiusSelector';
//import UserInfoSelector from './userPreferenceComponents/userInfo';
import LocationSelector from './userPreferenceComponents/locationSelector';
import StabilizeSituationQuestions from './userPreferenceComponents/stabilizeSituation';

import { Link } from 'gatsby';

//Global Data Store
import { globalContext } from '../GlobalContext';

import API from '../API';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

/**
 * Display to allow a user to update their profile - service preferences, demographics, search radius, etc.
 * @param {*} props 
 * @author BrianCastor
 */
export default function Profile(props) {
  const context = useContext(globalContext);
  const [userData, setUserData] = React.useState(context.currentUser || {});
  const [error, setError] = React.useState(null);
  const [isEdited, setIsEdited] = React.useState(false);

  //Redirect to /home if user is already signed in
  if (!context.isRegistered()) {
    context.navigateSafe('/');
  }

  function updateUserData(newData) {
    let temp = { ...userData };
    temp = Object.assign(temp, newData);
    setUserData(temp);
    setIsEdited(true);
  }

  function save() {
    let temp = {...userData}
    API.updateContact(temp).then(data => {
      context.updateUserPreferences(temp);
      setIsEdited(false);
      setError(null);
    }).catch(err => {
      setError(err.toString());
    })
  }

  return (
  <React.Fragment>
    <div style={{ marginBottom: '100px', backgroundColor: 'white', padding: '2px 15px 15px 15px' }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <h2>Profile</h2>
        </Grid>
        <Grid item>
          <Button color="inherit" variant="contained" component={Link} to="/home">Navigate Home</Button>
        </Grid>
      </Grid>
      
      <LocationSelector userData={userData} updateUserData={updateUserData} />
      <SearchRadiusSelector userData={userData} updateUserData={updateUserData} />
      <LanguageSelector userData={userData} updateUserData={updateUserData} />
      <ServicesSelector userData={userData} updateUserData={updateUserData} />
      <DemographicsSelector userData={userData} updateUserData={updateUserData} />
      <StabilizeSituationQuestions userData={userData} updateUserData={updateUserData} />
      {/*<UserInfoSelector userData={userData} updateUserData={updateUserData} />*/}

      <h3>Reset Postings I've Previously Hidden</h3>
      <Button
        variant="contained"
        color="default"
        onClick={() => {
          context.resetRejectedPostings();
        }}
      >
        Reset Hidden Postings
      </Button>

      <h3>Reset User Data</h3>
      <Button
        variant="contained"
        color="default"
        onClick={() => {
          context.clearUserPreferences();
        }}
      >
        Clear Profile Data
      </Button>
    </div>
    {isEdited ? 
    (
      <div style={{ position: 'fixed', bottom: 0, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center', backgroundColor:'white' }}>
        <Paper elevation={3}>
        <div style={{paddingBottom:'15px', paddingTop:'15px'}}>
          <Button color="inherit" component={Link} to="/profile" style={{marginRight:'20px'}}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={() => save()}>Save</Button>
          <br/>
          <span style={{color:'red'}}>{error}</span>
        </div>
        </Paper>
      </div>
    )
    :
    (null)
    }
    </React.Fragment>
  )

}